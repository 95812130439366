import {
	createAsyncThunk,
	createSlice,
	PayloadAction,
	createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "./store";
import api from "../api";
import {
	addAppUserFace,
	updateAppUserFace,
	deleteAppUserFace,
} from "../api/admin";
export interface appUsersFace {
	list: oneAppUserFace[];
	status: "idle" | "loading" | "failed" | "succeeded";
}
const initialState: appUsersFace = {
	list: [],
	status: "idle",
};
export interface oneAppUserFace {
	id: number;
	email: string;
	alias: string;
	appId: number;
	createdDate: string;
	lastModifiedDate: string;
	loginId: number;
	lastLoginIp: string;
	lastLoginTime: string;
	modifiedBy: number;
	modifiedByUser: string;
	status: number;
	roleList: roleFace[];
}
interface roleFace {
	UserId: number;
	roleId: number;
	roleName: string;
}

export const fetchAppUsers = createAsyncThunk(
	"appUsers/fetch",
	async (appId: number, { rejectWithValue }) => {
		const result = await api.admin.getAppUsers({ appId });
		if (!result) return rejectWithValue("fail");
		return result.data;
	}
);
export const addAppUser = createAsyncThunk(
	"appUsers/add",
	async (data: addAppUserFace, { rejectWithValue }) => {
		const result = await api.admin.addAppUser(data);
		if (!result) return rejectWithValue("fail");
		return result.data;
	}
);
export const updateAppUser = createAsyncThunk(
	"appUsers/update",
	async (data: updateAppUserFace, { rejectWithValue }) => {
		const result = await api.admin.updateAppUser(data);
		if (!result) return rejectWithValue("fail");
		return result.data;
	}
);
export const deleteAppUser = createAsyncThunk(
	"appUsers/delete",
	async (data: deleteAppUserFace, { rejectWithValue }) => {
		const { name, userId, appId } = data;
		const result = await api.admin.deleteAppUser({ userId, appId });
		if (!result) return rejectWithValue("fail");
		return { name, userId };
	}
);
export const appUserSlice = createSlice({
	name: "appUsers",
	initialState,
	reducers: {
		setList: (state, action: PayloadAction<oneAppUserFace[]>) => {
			state.list = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAppUsers.pending, (state) => {
				state.status = "loading";
			})
			.addCase(
				fetchAppUsers.fulfilled,
				(state, action: PayloadAction<oneAppUserFace[]>) => {
					state.status = "succeeded";
					state.list = action.payload;
				}
			)
			.addCase(fetchAppUsers.rejected, (state) => {
				state.status = "failed";
			})
			.addCase(addAppUser.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addAppUser.fulfilled, (state) => {
				state.status = "succeeded";
			})
			.addCase(addAppUser.rejected, (state) => {
				state.status = "failed";
			})
			.addCase(deleteAppUser.pending, (state) => {
				state.status = "loading";
			})
			.addCase(deleteAppUser.fulfilled, (state, action: PayloadAction<any>) => {
				const { userId } = action.payload;
				state.status = "succeeded";
				state.list.splice(
					state.list.findIndex((user) => user.id === userId),
					1
				);
			})
			.addCase(deleteAppUser.rejected, (state) => {
				state.status = "failed";
			});
	},
});

export const { setList } = appUserSlice.actions;
export const selectAppUsers = (state: RootState) => state.appUsers;

export default appUserSlice.reducer;
