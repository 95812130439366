import React, { useEffect } from "react";
import style from "../account/Account.module.scss";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StyledTableCell from "../../components/StyledTableCell";
import StyledTableRow from "../../components/StyledTableRow";
import api from "../../api";
import { useAppDispatch, useAppSelector } from "../../reducer/hooks";
import { alerting } from "../../reducer/alerterSlice";
import {
	selectAppAdmin,
	filterRoleList,
	deleteFromRoleList,
	fetchRoleList,
	setSelectedRole,
} from "../../reducer/appAdminSlice";
import Typography from "@mui/material/Typography";
import { selectCurrentApp } from "../../reducer/appListSlice";
import ConfirmDialog from "../../components/ConfirmDialog";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {
	selectConfirmDialog,
	openConfirmDialog,
} from "../../reducer/confirmDialogSlice";
import { IRole } from "../../interface/appAdminFace";
import Box from "@mui/material/Box";

export default function RoleManagement() {
	const { t } = useTranslation();
	let navigate = useNavigate();
	const dispatch = useAppDispatch();
	const state = useAppSelector(selectAppAdmin);
	const currentApp = useAppSelector(selectCurrentApp);
	const confirmDialog = useAppSelector(selectConfirmDialog);

	const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > 0) {
			dispatch(filterRoleList(e.target.value));
		} else {
			dispatch(fetchRoleList(currentApp!.appId));
		}
	};

	const onRoleDelete = async (id: number) => {
		const result: any = await api.admin.deleteRole(id).catch(Error);
		if (result.returnCode < 0)
			return dispatch(alerting("error", result.message));
		dispatch(deleteFromRoleList(id));
		dispatch(alerting("success", "Role Delete Success"));
	};

	function navigateToRoleEdit(role: any) {
		dispatch(setSelectedRole(role));
		navigate(`/app-admin/role-management/edit-role/roleId=${role.roleId}`);
	}

	useEffect(() => {
		if (currentApp && currentApp.appId) {
			dispatch(fetchRoleList(currentApp!.appId));
		}
	}, [currentApp, dispatch]);

	return (
		<div className={style["content-wrap"]}>
			<div className={style["title"]}>{t("appAdmin.roleManagement")}</div>
			<div className={style["li-containers-row"]}>
				<div className={style["li-container"]}>
					<div className={`${style["li-button"]} ${style["li-button-role"]}`}>
						<Button
							variant="contained"
							onClick={() => navigate("/app-admin/role-management/create-role")}
						>
							{t("appAdmin.createRole")}
						</Button>
					</div>
				</div>
				<div className={style["li-container"]}>
					<div className={style["li-value"]}>
						<TextField
							name="roleSearch"
							onChange={onChangeInput}
							variant="outlined"
							placeholder={t("appAdmin.search")}
							inputProps={{
								style: {
									padding: 5,
								},
							}}
						/>
					</div>
					{/* <div className={`${style["li-button"]} ${style["side-button"]}`}>
                        <Button variant="contained">{t("appAdmin.search")}</Button>
                    </div> */}
				</div>
			</div>
			{state.roleList && state.roleList.length ? (
				<TableContainer component={Paper}>
					<Table sx={{ maxWidth: 1000 }} aria-label="customized table">
						<TableHead>
							<TableRow>
								{/* <StyledTableCell align="center">{t("appAdmin.email")}</StyledTableCell> */}
								<StyledTableCell align="center">
									{t("appAdmin.role")}
								</StyledTableCell>
								<StyledTableCell>{t("appAdmin.description")}</StyledTableCell>
								<StyledTableCell align="center">
									{t("appAdmin.status")}
								</StyledTableCell>
								<StyledTableCell align="center">
									{t("appAdmin.setting")}
								</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{state.roleList.map((role: IRole) => (
								<StyledTableRow key={role.roleId}>
									{/* <StyledTableCell align="right">{role.modifiedByUser}</StyledTableCell> */}
									<StyledTableCell align="right">
										{role.roleName}
									</StyledTableCell>
									<StyledTableCell component="th" scope="row">
										{role.description}
									</StyledTableCell>
									<StyledTableCell align="right">
										<Typography
											noWrap
											component="div"
											color={
												role.status === 1 ? "var(--enabled)" : "var(--disabled)"
											}
										>
											{role.status === 1
												? t("appAdmin.enabled")
												: t("appAdmin.disabled")}
										</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										<Button
											variant="contained"
											sx={{ backgroundColor: "var(--main)" }}
											onClick={() => navigateToRoleEdit(role)}
										>
											{t("superAdmin.edit")}
										</Button>
										<Button
											variant="contained"
											sx={{ backgroundColor: "var(--black)" }}
											onClick={() =>
												dispatch(
													openConfirmDialog({
														id: role.roleId,
														name: role.roleName,
													})
												)
											}
										>
											{t("superAdmin.delete")}
										</Button>
									</StyledTableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<Box sx={{ display: "flex", mx: 5, justifyContent: "center" }}>
					<Typography noWrap component="div" color={"var(--disabled)"}>
						{t("appAdmin.noRecord")}
					</Typography>
				</Box>
			)}

			<div>
				<ConfirmDialog
					title="Delete Role?"
					open={confirmDialog.open}
					onConfirm={() => onRoleDelete(confirmDialog.id)}
				>
					{` Are you sure you want to delete Role :${confirmDialog.name} ?`}
				</ConfirmDialog>
			</div>
		</div>
	);
}
