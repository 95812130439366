const dictionary = {
	currency: "货币",
	login: {
		password: "密码",
	},
	register: "注冊",
	visitor: {
		ga: "ga",
		verify: "Verify",
		enterVerifyCode: "Enter Verify Code",
		sendAgain: "Send Again",
		submit: "Submit",
		accountLogin: "Account Login",
		login: "login",
		email: "email",
		password: "password",
		rememberMeNextTime: "remember me next time",
		confirm: "Confirm",
		notRegistered: "Not Registered",
		forgotPassword: "Forgot Password",
	},
	account: {
		userAuth: "User Auth",
		accountInfo: "Account Info",
		googleAuthentication: "Google Authentication",
		loginName: "Login Name",
		password: "Password",
		email: "Email",
		changePassword: "Change Password",
		updateEmail: "Update Email",
		submit: "Submit",
		back: "back",
		emailVerifyCode: "Email Verify Code",
		sendVerifyCode: "Send Verify Code",
		status: "Status",
		request: "Request",
		forgetPassword: "Forget Password",
		oldPassword: "Old Password",
		newPassword: "New Password",
		confirmNewPassword: "New Password",
	},
};
export default dictionary;
