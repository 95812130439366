import lodashHas from "lodash/has";
import flow from "lodash/flow";
import { IParentFeatureList } from "../interface/appAdminFace";
export {flow}
export function generateNonce() {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

export function numberToBol(number: any) {
	let result = false;
	if (number === 1) {
		result = true;
	} else if (number === -1) {
		result = false;
	}
	return result;
}

export function filterDuplicate(arr: number[], id: number) {
	let result: number[] = [];
	if (!arr.includes(id)) {
		result = [...arr, id];
	} else {
		result = arr.filter((element) => id !== element);
	}
	return result;
}

export function filterDuplicateArray(array1: number[], array2: number[]) {
	let result: number[] = [];
	result = array1.filter((val) => !array2.includes(val));
	return result;
}

export function filterDifferenceArray(array1: number[], array2: number[]) {
	let result: number[] = [];
	result = array1.filter((val) => array2.includes(val));
	return result;
}

export function removeNegative(array: number[]) {
	if (array.includes(-1)) {
		array.shift();
	}
	return array;
}

export function hasPath(obj: any, paths: string | Array<string>) {
	if (Array.isArray(paths)) {
		return paths.every((path) => lodashHas(obj, path));
	} else {
		return lodashHas(obj, paths);
	}
}

export function getLeafNodes(nodes: any, result: any = []) {
	for (var i = 0, length = nodes.length; i < length; i++) {
		if (!nodes[i].children || nodes[i].children.length === 0) {
			result.push(nodes[i].id);
		} else {
			result = getLeafNodes(nodes[i].children, result);
		}
	}
	return result;
}
export function renameObjKey(obj: any, oldName: string, newName: string) {
	const { [oldName]: value, ...rest } = obj;
	return {
		...rest,
		[newName]: value,
	};
}
export function getItemsByIds(
	ids: string[],
	list: { id: number; name: string }[]
) {
	let dict: any = {};
	let result = [];
	for (let id of ids) {
		dict[id] = true;
	}
	for (let item of list) {
		if (item.id in dict) {
			result.push(item);
		}
	}
	return result;
}

export function getAllChildrenId(array: Array<any>) {
	let arrayOutput: Array<any> = []
	if (array) {
		for (let i = 0; i < array.length; i++) {
			if (!array[i].children) {
				arrayOutput.push(array[i].id)
			} else {
				arrayOutput = getAllChildrenId(array[i].children)
			}
		}
	}
	return arrayOutput
}


interface getAllParentIdsFace{
    [ticked: number]: boolean;
}
export function getAllParentIds(list:IParentFeatureList, str:string, path={}):getAllParentIdsFace{
    const isLeaf= list?.children?.length===0;
    if(isLeaf){
        const isFound= str.includes(list.id.toString());
        return isFound ? path : {}; 
    }
    let ticked={};
    for(let i=0;i<list.children.length;i++){
        let node= list.children[i];
        let newPath= {...path, [node.id]:true};
        let childTicked= getAllParentIds(node, str, newPath);
        ticked= {...ticked, ...childTicked}
    }
    return ticked;
}
