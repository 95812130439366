import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import style from "../account/Account.module.scss";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import api from "../../api";
import { alerting } from "../../reducer/alerterSlice";
import { useAppDispatch, useAppSelector } from "../../reducer/hooks";
import TreeView from "@mui/lab/TreeView";
import MinusSquare from "../../components/MinusSquare";
import PlusSquare from "../../components/PlusSquare";
import CloseSquare from "../../components/CloseSquare";
import StyledTreeItem from "../../components/StyledTreeItem";
import Checkbox from "@mui/material/Checkbox";
import { fetchFeatureList, selectAppAdmin } from "../../reducer/appAdminSlice";
import {
	filterDifferenceArray,
	generateNonce,
	getLeafNodes,
	removeNegative,
	getAllParentIds,
	flow,
} from "../../utils/helper";
import { getChildById } from "../../utils/treeview";
import { IFeature, IParentFeatureList } from "../../interface/appAdminFace";
import { selectCurrentApp } from "../../reducer/appListSlice";
import FormControlLabel from "@mui/material/FormControlLabel";

const initUserInputs = {
	roleName: "",
	description: "",
};

export default function CreateRole() {
	const { t } = useTranslation();
	let navigate = useNavigate();
	const dispatch = useAppDispatch();
	const popsError = (text: string) => dispatch(alerting("error", text));
	const [userInputs, setUserInputs] = useState(initUserInputs);
	const state = useAppSelector(selectAppAdmin);
	const currentApp = useAppSelector(selectCurrentApp);
	const [selectedIds, rawSetSelectedIds] = useState<number[]>([]);
	const setSelectedIds = (tickedIds: Array<number>) => {
		const wrapFn = flow(
			getAllParentIds,
			Object.keys,
			(ids) => ids.map((str) => Number(str)),
			rawSetSelectedIds
		);
		const initPath = { ["-1"]: true };
		const stringIds = tickedIds.join(",");
		wrapFn(parentFeatureList, stringIds, initPath);
	};

	const parentFeatureList: IParentFeatureList = {
		id: -1,
		name: "All Feature",
		children: state.featureList,
		featureType: 1,
	};

	const setInputData = (e: React.ChangeEvent<HTMLInputElement>) => {
		const key = e.target.name;
		const value = e.target.value;
		setUserInputs((data) => ({ ...data, [key]: value }));
	};

	const onRoleCreate = async () => {
		const selectedLeafNodes = getLeafNodes([parentFeatureList]);
		const selectedFeatureList = removeNegative(
			filterDifferenceArray(selectedLeafNodes, selectedIds)
		);
		const nonce = generateNonce();
		if (selectedFeatureList.length === 0)
			return popsError("Feature Cannot be Empty");
		if (!userInputs.roleName || !userInputs.description)
			return popsError("Input Field Cannot be Empty");
		const data: any = {
			nonce,
			appId: currentApp!.appId,
			roleName: userInputs.roleName,
			description: userInputs.description,
			featureList: selectedFeatureList,
		};
		let result: any = null;
		result = await api.admin.createRole(data).catch(Error);
		if (result && result.returnCode < 0) return popsError(result.message);
		navigate(-1);
		const message = `Role ${userInputs.roleName} Create Success`;
		dispatch(alerting("success", message));
	};

	function getOnChange(checked: boolean, nodes: IFeature | IParentFeatureList) {
		const allNode: number[] = getChildById(parentFeatureList, nodes.id);
		let array = checked
			? [...selectedIds, ...allNode]
			: selectedIds.filter((value) => !allNode.includes(value));

		array = array.filter((v, i) => array.indexOf(v) === i);
		setSelectedIds(array);
	}

	const renderTree = (nodes: IFeature | IParentFeatureList) => (
		<StyledTreeItem
			key={nodes.id}
			nodeId={nodes.id.toString()}
			label={
				<FormControlLabel
					control={
						<Checkbox
							checked={selectedIds.some((item) => item === nodes.id)}
							onChange={(event) =>
								getOnChange(event.currentTarget.checked, nodes)
							}
							onClick={(e) => e.stopPropagation()}
						/>
					}
					label={<>{nodes.name}</>}
					key={nodes.id}
				/>
			}
		>
			{Array.isArray(nodes.children)
				? nodes.children.map((node: any) => renderTree(node))
				: null}
		</StyledTreeItem>
	);

	useEffect(() => {
		if (currentApp && currentApp.appId) {
			dispatch(fetchFeatureList(currentApp!.appId));
		}
	}, []);

	return (
		<div className={style["content-wrap"]}>
			<div className={style["title"]}>{t("appAdmin.createRole")}</div>
			<div className={style["li-container"]}>
				<div className={`${style["li-label"]} ${style["li-label-long"]}`}>
					{t("appAdmin.roleName")}
				</div>
				<div className={style["li-value"]}>
					<TextField
						id="outlined"
						name="roleName"
						variant="outlined"
						onChange={setInputData}
						inputProps={{
							style: {
								padding: 5,
							},
						}}
					/>
				</div>
			</div>
			<div className={style["li-container"]}>
				<div className={`${style["li-label"]} ${style["li-label-long"]}`}>
					{t("appAdmin.description")}
				</div>
				<div className={style["li-value"]}>
					<TextField
						id="outlined"
						name="description"
						variant="outlined"
						onChange={setInputData}
						inputProps={{
							style: {
								padding: 5,
							},
						}}
					/>
				</div>
			</div>
			<div className={style["li-container"]}>
				<div className={`${style["li-label"]} ${style["li-label-long"]}`}>
					{t("appAdmin.assignedRoles")}
				</div>
			</div>
			<TreeView
				aria-label="customized"
				defaultExpanded={["-1"]}
				defaultCollapseIcon={<MinusSquare />}
				defaultExpandIcon={<PlusSquare />}
				defaultEndIcon={<CloseSquare />}
				sx={{ height: 264, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
				multiSelect={true}
			>
				{parentFeatureList && renderTree(parentFeatureList)}
			</TreeView>
			<div className={`${style["li-button"]} ${style["li-button-long"]}`}>
				<Button color="primary" variant="contained" onClick={onRoleCreate}>
					{t("account.submit")}
				</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={() => navigate("/app-admin/role-management")}
				>
					{t("account.back")}
				</Button>
			</div>
		</div>
	);
}
