import { useState } from "react";
import style from "../../styles/contentWrap.module.scss";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { useAppDispatch } from "../../reducer/hooks";
import { generateNonce } from "../../utils/helper";
import { alerting } from "../../reducer/alerterSlice";
import api from "../../api";
import LiItem from "../../components/LiItem";

const initUserInput = {
	appName: "",
	loginCallbackUrl: "",
	isEnableTwoFA: "0",
	iconUrl: "",
	loginTokenDuration: 60,
};
export default function CreateApp() {
	const [userInput, setUserInput] = useState(initUserInput);
	const dispatch = useAppDispatch();
	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const key = e.target.name;
		const value = e.target.value;
		setUserInput((s) => ({ ...s, [key]: value }));
	};
	const onSelectChange = (e: SelectChangeEvent<string>) => {
		const key = e.target.name;
		const value = e.target.value;
		setUserInput((s) => ({ ...s, [key]: value }));
	};
	const submit = async () => {
		if (userInput.appName === "")
			return dispatch(alerting("warning", "app name is empty"));
		if (userInput.loginCallbackUrl === "")
			return dispatch(alerting("warning", "login URL is empty"));
		const params = {
			...userInput,
			isEnableTwoFA: userInput.isEnableTwoFA === "1",
			loginTokenDuration: Number(userInput.loginTokenDuration) * 60,
		};
		const nonce = generateNonce();
		const result: any = await api.superAdmin
			.createApp({ nonce, ...params })
			.catch(Error);
		if (!result) return;
		dispatch(alerting("success", `${params.appName} is created`));
		setUserInput({ ...initUserInput });
	};
	return (
		<div className={`${style["content-wrap"]}`}>
			<div className={style["title"]}>Create App</div>
			<LiItem title="App Name" mustFill>
				<TextField
					variant="outlined"
					name="appName"
					value={userInput.appName}
					onChange={onInputChange}
				/>
			</LiItem>
			<LiItem title="Login URL" mustFill>
				<TextField
					variant="outlined"
					name="loginCallbackUrl"
					value={userInput.loginCallbackUrl}
					onChange={onInputChange}
				/>
			</LiItem>
			<LiItem title="Enable Two Fa">
				<Select
					name="isEnableTwoFA"
					value={userInput.isEnableTwoFA}
					onChange={onSelectChange}
					displayEmpty
				>
					<MenuItem value="1">true</MenuItem>
					<MenuItem value="0">false</MenuItem>
				</Select>
			</LiItem>
			<LiItem title="Icon URL">
				<TextField
					variant="outlined"
					name="iconUrl"
					value={userInput.iconUrl}
					onChange={onInputChange}
				/>
			</LiItem>
			<LiItem title="Token Duration (Minute)">
				<TextField
					variant="outlined"
					name="loginTokenDuration"
					type="number"
					onChange={onInputChange}
					value={userInput.loginTokenDuration}
				/>
			</LiItem>
			<div className={style["li-button"]}>
				<Button color="primary" variant="contained" onClick={submit}>
					Create
				</Button>
			</div>
		</div>
	);
}
