import React from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { getItemsByIds } from "../utils/helper";

interface propFace {
	title?: string;
	list: { id: number; name: string }[];
	selectedItems: string[];
	setSelected: React.Dispatch<React.SetStateAction<never[]>>;
}
export default function MultipleSelectChip(props: propFace) {
	const { list, selectedItems, setSelected, title = "" } = props;

	const handleChange = (event: any) => {
		const { value } = event.target;
		setSelected(
			// On autofill we get a stringified value.
			typeof value === "string" ? value.split(",") : value
		);
	};
	return (
		<FormControl sx={{ width: 300 }}>
			<InputLabel>{title}</InputLabel>
			<Select
				labelId="multiple-chip-label"
				multiple
				value={selectedItems}
				onChange={handleChange}
				input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
				renderValue={(selected) => (
					<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
						{getItemsByIds(selected, list).map((item) => (
							<Chip key={item.id} label={item.name} />
						))}
					</Box>
				)}
			>
				{list.map((item) => (
					<MenuItem key={item.id} value={item.id}>
						{item.name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
