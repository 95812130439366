import {
	createAsyncThunk,
	createSlice,
	PayloadAction,
	createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "./store";
import api from "../api";

export interface ItemState {
	appId: number;
	isEnableTwoFA: boolean;
	appName: string;
	loginTokenDuration: number;
	oneOffTokenDuration: number;
	secret: string;
	loginCallbackUrl: string;
	iconUrl: string;
}
export interface ListingState {
	status: "idle" | "loading" | "failed";
	selected: number;
	list: ItemState[];
}

const initialState: ListingState = {
	status: "idle",
	selected: -1,
	list: [],
};

export const fetchAppList = createAsyncThunk(
	"superAppList/fetch",
	async (_, { rejectWithValue }) => {
		const result = await api.superAdmin.superAppList();
		if (!result) return rejectWithValue("fail");
		return result.data;
	}
);

export const superAppListSlice = createSlice({
	name: "superAppList",
	initialState,
	reducers: {
		setList: (state, action: PayloadAction<ItemState[]>) => {
			state.list = action.payload;
		},
		setSelected: (state, action: PayloadAction<number>) => {
			state.selected = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAppList.pending, (state) => {
				state.status = "loading";
			})
			.addCase(
				fetchAppList.fulfilled,
				(state, action: PayloadAction<ItemState[]>) => {
					state.status = "idle";
					const list = action.payload
						.filter((item) => item.appId !== 2)
						.sort((a, b) => a.appId - b.appId);
					state.list = list;
					if (action.payload.length > 0) state.selected = list[0]?.appId;
				}
			)
			.addCase(fetchAppList.rejected, (state) => {
				state.status = "failed";
			});
	},
});

export const { setList, setSelected } = superAppListSlice.actions;
export const selectLister = (state: RootState) => state.superAppLister;

export const selectCurrentApp = createSelector(selectLister, (appList) =>
	appList.list.find((app) => app.appId === appList.selected)
);

export default superAppListSlice.reducer;
