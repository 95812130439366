import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import style from "../account/Account.module.scss";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import api from "../../api";
import { useAppDispatch, useAppSelector } from "../../reducer/hooks";
import { alerting } from "../../reducer/alerterSlice";
import {
	fetchFeatureList,
	selectAppAdmin,
	setNewFeatureEnabled,
	setNewFeatureType,
} from "../../reducer/appAdminSlice";
import TreeView from "@mui/lab/TreeView";
import MinusSquare from "../../components/MinusSquare";
import PlusSquare from "../../components/PlusSquare";
import CloseSquare from "../../components/CloseSquare";
import StyledTreeItem from "../../components/StyledTreeItem";
import { IFeatureList, IFeature } from "../../interface/appAdminFace";
import { selectCurrentApp } from "../../reducer/appListSlice";
import {
	openConfirmDialog,
	selectConfirmDialog,
} from "../../reducer/confirmDialogSlice";
import ConfirmDialog from "../../components/ConfirmDialog";
import MenuIcon from "@mui/icons-material/MenuBook";
import FunctionIcon from "@mui/icons-material/Build";

const initUserInputs = {
	name: "",
	code: "",
	tag: "",
};

const initPickedFeature = {
	appId: 0,
	children: [],
	code: "",
	createdDate: "",
	featureType: 1,
	id: 0,
	isEnabled: true,
	lastModifiedDate: "",
	modifiedBy: "",
	name: "",
	parentFeatureId: 0,
	permission: 0,
	tag: "",
};
export default function FeatureManagement() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const state = useAppSelector(selectAppAdmin);
	const [userInputs, setUserInputs] = useState(initUserInputs);
	const [formType, setFormType] = useState("EDIT");
	const rootFeatureList = { ...initPickedFeature, children: state.featureList };
	const [pickedFeature, setPickedFeature] = useState<IFeature>(rootFeatureList);
	const currentApp = useAppSelector(selectCurrentApp);
	const confirmDialog = useAppSelector(selectConfirmDialog);

	const handleEditIsEnabledChange = (event: any) => {
		setPickedFeature((f) => ({ ...f, isEnabled: event.target.value }));
	};

	function handleEditFeatureSelected(event: any) {
		setPickedFeature((f) => ({ ...f, featureType: event.target.value }));
	}

	const handleAddIsEnabledChange = (event: any) => {
		dispatch(setNewFeatureEnabled(event.target.value));
	};

	function handleAddFeatureSelected(event: any) {
		dispatch(setNewFeatureType(event.target.value));
	}

	const setInputData = (e: React.ChangeEvent<HTMLInputElement>) => {
		const key = e.target.name;
		const value = e.target.value;
		setUserInputs((data) => ({ ...data, [key]: value }));
	};

	const onFeatureAddSave = async () => {
		if (pickedFeature.featureType === 2 && state.newFeature.featureType === 1)
			return popsError("Cannot add Menu under Function");
		if (!userInputs.name || !userInputs.code || !userInputs.tag)
			return popsError("Input Field Cannot be Empty");
		const isEnabled = state.newFeature.isEnabled === "true";
		const data = {
			appId: currentApp!.appId,
			name: userInputs.name,
			code: userInputs.code,
			featureType: state.newFeature.featureType,
			parentFeatureId: pickedFeature.id,
			isEnabled,
			tag: userInputs.tag,
		};
		const result = await api.admin.createFeature(data).catch(Error);
		if (!result) return;
		dispatch(fetchFeatureList(currentApp!.appId));
		dispatch(alerting("success", "Feature Save Success"));
		setFormType("EDIT");
		setFormType("ADD");
	};

	const onFeatureUpdateSave = async () => {
		const data = {
			appId: currentApp!.appId,
			name: userInputs.name.length > 0 ? userInputs.name : pickedFeature.name,
			code: userInputs.code.length > 0 ? userInputs.code : pickedFeature.code,
			featureType: pickedFeature.featureType,
			parentFeatureId: pickedFeature.parentFeatureId,
			isEnabled: Number(pickedFeature.isEnabled),
			tag: userInputs.tag.length > 0 ? userInputs.tag : pickedFeature.tag,
			updateField: 253,
		};
		let result: any = null;
		result = await api.admin.updateFeature(pickedFeature.id, data).catch(Error);
		if (!result) return popsError(result.message);
		dispatch(fetchFeatureList(currentApp!.appId));
		dispatch(alerting("success", "Feature Save Success"));
	};

	const onFeatureDelete = async () => {
		if (!pickedFeature.id) return popsError("No Feature is selected");
		const result: any = await api.admin
			.deleteFeature(Number(pickedFeature.id))
			.catch(Error);
		if (result.returnCode < 0) return popsError(result.message);
		dispatch(fetchFeatureList(currentApp!.appId));
		dispatch(alerting("success", "Feature Delete Success"));
	};

	function getTreeItemsFromData(featureList: IFeatureList) {
		return featureList.map((featureListItem) => {
			let children = undefined;
			if (featureListItem.children && featureListItem.children.length > 0) {
				children = getTreeItemsFromData(featureListItem.children);
			}
			return (
				<StyledTreeItem
					ContentProps={{
						onClick: () => {
							setPickedFeature(featureListItem);
							setUserInputs(featureListItem);
							setFormType("EDIT");
						},
					}}
					key={featureListItem.id}
					nodeId={featureListItem.id.toString()}
					label={
						<Box sx={{ display: "flex" }}>
							<Box>
								{featureListItem.featureType === 1 ? (
									<MenuIcon />
								) : (
									<FunctionIcon />
								)}
							</Box>
							<Box>{featureListItem.name}</Box>
						</Box>
					}
					children={children}
				/>
			);
		});
	}

	const popsError = (text: string) => dispatch(alerting("error", text));

	useEffect(() => {
		if (currentApp && currentApp.appId) {
			dispatch(fetchFeatureList(currentApp!.appId));
		}
	}, [currentApp, dispatch]);

	return (
		<div className={style["content-wrap"]}>
			<div className={style["title"]}>{t("appAdmin.featureManagement")}</div>
			<div className={style["feature-container"]}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						flex: 1,
						maxWidth: 500,
					}}
				>
					<Box sx={{ display: "flex", mb: 5, justifyContent: "space-between" }}>
						<span style={{ alignSelf: "center", marginRight: "1rem" }}>
							{t("appAdmin.type")}
						</span>
						<Button
							color={formType === "ADD" ? "success" : "primary"}
							variant="contained"
							onClick={() => setFormType("ADD")}
						>
							{t("appAdmin.add")}
						</Button>
						{pickedFeature.id > 0 && (
							<Button
								color="error"
								variant="contained"
								onClick={() =>
									dispatch(
										openConfirmDialog({
											id: pickedFeature.id,
											name: pickedFeature.name,
										})
									)
								}
							>
								{t("appAdmin.delete")}
							</Button>
						)}
					</Box>
					<Box
						sx={{
							display: "flex",
							border: "black 1px solid",
							height: "calc(100vh - 300px)",
							overflowY: "auto",
						}}
					>
						<TreeView
							aria-label="customized"
							defaultExpanded={["0"]}
							// defaultSelected="0"
							defaultCollapseIcon={<MinusSquare />}
							defaultExpandIcon={<PlusSquare />}
							defaultEndIcon={<CloseSquare />}
							sx={{
								height: "100%",
								flexGrow: 1,
								maxWidth: 500,
								overflowY: "auto",
							}}
						>
							{rootFeatureList && rootFeatureList.children?.length ? (
								getTreeItemsFromData([rootFeatureList])
							) : (
								<Box sx={{ display: "flex", mx: 5 }}>No Record</Box>
							)}
						</TreeView>
					</Box>
				</Box>
				<Box sx={{ display: "flex", flex: 1 }}>
					{formType === "EDIT" && pickedFeature.id !== 0 && (
						<div className={`${style["form-container"]}`}>
							<div className={style["li-container"]}>
								<div className={`${style["li-label"]}`}>
									{t("appAdmin.name")}
								</div>
								<div className={style["li-value"]}>
									<TextField
										key={`key-${pickedFeature.name}`}
										name={`name`}
										variant="outlined"
										onChange={setInputData}
										defaultValue={pickedFeature.name}
									/>
								</div>
							</div>
							<div className={style["li-container"]}>
								<div className={`${style["li-label"]}`}>
									{t("appAdmin.code")}
								</div>
								<div className={style["li-value"]}>
									<TextField
										key={`code-${pickedFeature.code}`}
										name={`code`}
										variant="outlined"
										onChange={setInputData}
										defaultValue={pickedFeature.code}
									/>
								</div>
							</div>
							<div className={style["li-container"]}>
								<div className={`${style["li-label"]}`}>
									{t("appAdmin.tag")}
								</div>
								<div className={style["li-value"]}>
									<TextField
										key={`tag-${pickedFeature.tag}`}
										name={`tag`}
										variant="outlined"
										onChange={setInputData}
										defaultValue={pickedFeature.tag}
									/>
								</div>
							</div>
							<div className={style["li-container"]}>
								<div className={`${style["li-label"]}`}>
									{t("appAdmin.featureType")}
								</div>
								<div className={style["li-value"]}>
									<Select
										key={`featureType-${pickedFeature.tag}`}
										defaultValue={pickedFeature.featureType}
										value={pickedFeature.featureType}
										onChange={handleEditFeatureSelected}
										sx={{
											color: "var(--black)",
											bgcolor: "var(--white)",
										}}
									>
										<MenuItem value={1}>{t("appAdmin.menu")}</MenuItem>
										<MenuItem value={2}>{t("appAdmin.function")}</MenuItem>
									</Select>
								</div>
							</div>
							<div className={style["li-container"]}>
								<div className={`${style["li-label"]}`}>
									{t("appAdmin.status")}
								</div>
								<div className={style["li-value"]}>
									<Select
										key={`isEnabled-${pickedFeature.tag}`}
										onChange={handleEditIsEnabledChange}
										defaultValue={pickedFeature.isEnabled}
										value={pickedFeature.isEnabled}
										sx={{
											color: "var(--black)",
											bgcolor: "var(--white)",
										}}
									>
										<MenuItem value={1}>{t("account.enabled")}</MenuItem>
										<MenuItem value={-1}>{t("account.disabled")}</MenuItem>
									</Select>
								</div>
							</div>
							<Box sx={{ display: "flex", justifyContent: "center" }}>
								<Button
									color="primary"
									variant="contained"
									onClick={onFeatureUpdateSave}
								>
									{t("appAdmin.save")}
								</Button>
							</Box>
						</div>
					)}
					{formType === "ADD" && (
						<div className={`${style["form-container"]}`}>
							<div className={style["li-container"]}>
								<div className={`${style["li-label"]}`}>
									{t("appAdmin.name")}
								</div>
								<div className={style["li-value"]}>
									<TextField
										key={`key-${pickedFeature.name}`}
										name={`name`}
										variant="outlined"
										onChange={setInputData}
										defaultValue=""
									/>
								</div>
							</div>
							<div className={style["li-container"]}>
								<div className={`${style["li-label"]}`}>
									{t("appAdmin.code")}
								</div>
								<div className={style["li-value"]}>
									<TextField
										key={`code-${pickedFeature.code}`}
										name={`code`}
										variant="outlined"
										onChange={setInputData}
										defaultValue={""}
									/>
								</div>
							</div>
							<div className={style["li-container"]}>
								<div className={`${style["li-label"]}`}>
									{t("appAdmin.tag")}
								</div>
								<div className={style["li-value"]}>
									<TextField
										key={`tag-${pickedFeature.tag}`}
										name={`tag`}
										variant="outlined"
										onChange={setInputData}
										defaultValue={""}
									/>
								</div>
							</div>
							<div className={style["li-container"]}>
								<div className={`${style["li-label"]}`}>
									{t("appAdmin.featureType")}
								</div>
								<div className={style["li-value"]}>
									<Select
										key={`featureType-${pickedFeature.tag}`}
										defaultValue={""}
										onChange={handleAddFeatureSelected}
										sx={{
											color: "var(--black)",
											bgcolor: "var(--white)",
										}}
									>
										<MenuItem value={1}>{t("appAdmin.menu")}</MenuItem>
										<MenuItem value={2}>{t("appAdmin.function")}</MenuItem>
									</Select>
								</div>
							</div>
							<div className={style["li-container"]}>
								<div className={`${style["li-label"]}`}>
									{t("appAdmin.status")}
								</div>
								<div className={style["li-value"]}>
									<Select
										key={`isEnabled-${pickedFeature.tag}`}
										onChange={handleAddIsEnabledChange}
										value={state.newFeature.isEnabled}
										sx={{
											color: "var(--black)",
											bgcolor: "var(--white)",
										}}
									>
										<MenuItem value="true">{t("account.enabled")}</MenuItem>
										<MenuItem value="false">{t("account.disabled")}</MenuItem>
									</Select>
								</div>
							</div>
							<Box sx={{ display: "flex", justifyContent: "center" }}>
								<Button
									color="primary"
									variant="contained"
									onClick={onFeatureAddSave}
								>
									{t("appAdmin.save")}
								</Button>
							</Box>
						</div>
					)}
				</Box>
			</div>
			<ConfirmDialog
				title="Delete Feature?"
				open={confirmDialog.open}
				onConfirm={() => onFeatureDelete()}
			>
				{` Are you sure you want to delete Feature :${confirmDialog.name} ?`}
			</ConfirmDialog>
		</div>
	);
}
