import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api";
import { RootState } from "./store";
import {
	IFeature,
	IRoleList,
	IRole,
	ISelectedRole,
	AppAdminState,
	IFeatureList,
} from "../interface/appAdminFace";
import { getAllChildrenId } from "../utils/helper";

const initialState: AppAdminState = {
	featureList: [],
	roleList: [],
	selectedFeature: {
		appId: 0,
		children: [],
		code: "",
		createdDate: "",
		featureType: 1,
		id: 0,
		isEnabled: true,
		lastModifiedDate: "",
		modifiedBy: "",
		name: "",
		parentFeatureId: 0,
		permission: 0,
		tag: "",
	},
	selectedRole: {
		status: 1,
		roleName: "",
		description: "",
		FeatureListId: [],
		FeatureList: [
			{
				appId: 0,
				children: [],
				code: "",
				createdDate: "",
				featureType: 1,
				id: 0,
				isEnabled: true,
				lastModifiedDate: "",
				modifiedBy: "",
				name: "",
				parentFeatureId: 0,
				permission: 0,
				tag: "",
			},
		],
	},
	newFeature: {
		isEnabled: "true",
		featureType: 1,
	},
};

export const fetchFeatureList = createAsyncThunk(
	"appAdmin/fetchFeatureList",
	async (appId: number) => {
		const result: any = await api.admin.featureList(appId);
		if (result && result.data && result.data.length) {
			return result.data;
		}
	}
);

export const fetchRoleList = createAsyncThunk(
	"appAdmin/fetchRoleList",
	async (appId: number) => {
		const result: any = await api.admin.roleList(appId).catch(Error);
		if (result && result.data && result.data.length) {
			return result.data;
		}
	}
);

export const fetchRoleDetail = createAsyncThunk(
	"appAdmin/fetchRoleDetail",
	async (roleId: number, thunkAPI) => {
		const result: any = await api.admin.roleDetail(roleId).catch(Error);
		if (result && result.data) {
			thunkAPI.dispatch(setSelectedRoleFeature(result.data.FeatureList));
			thunkAPI.dispatch(
				setSelectedRoleFeatureListId(getAllChildrenId(result.data.FeatureList))
			);
			return result.data;
		}
	}
);

export const appAdminSlice = createSlice({
	name: "appAdmin",
	initialState,
	reducers: {
		setSelectedFeature: (state, action: PayloadAction<IFeature>) => {
			state.selectedFeature = action.payload;
		},
		setSelectedFeatureEnabled: (state, action: PayloadAction<boolean>) => {
			state.selectedFeature.isEnabled = action.payload;
		},
		setSelectedFeatureType: (state, action: PayloadAction<number>) => {
			state.selectedFeature.featureType = action.payload;
		},
		setNewFeatureEnabled: (state, action: PayloadAction<string>) => {
			state.newFeature.isEnabled = action.payload;
		},
		setNewFeatureType: (state, action: PayloadAction<number>) => {
			state.newFeature.featureType = action.payload;
		},
		setRoleList: (state, action: PayloadAction<IRoleList>) => {
			state.roleList = action.payload;
		},
		deleteFromRoleList: (state, action: PayloadAction<number>) => {
			state.roleList = state.roleList.filter(
				(element) => element.roleId !== action.payload
			);
		},
		filterRoleList: (state, action: PayloadAction<string>) => {
			state.roleList = state.roleList.filter((element) =>
				element.roleName.toLowerCase().includes(action.payload)
			);
		},
		setSelectedRole: (state, action: PayloadAction<ISelectedRole>) => {
			state.selectedRole.roleName = action.payload.roleName;
			state.selectedRole.status = action.payload.status;
			state.selectedRole.description = action.payload.description;
		},
		setSelectedRoleEnabled: (state, action: PayloadAction<number>) => {
			state.selectedRole.status = action.payload;
		},
		setSelectedRoleFeature: (state, action: PayloadAction<IFeatureList>) => {
			state.selectedRole.FeatureList = action.payload;
		},
		setSelectedRoleFeatureListId: (state, action: PayloadAction<any>) => {
			state.selectedRole.FeatureListId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchFeatureList.fulfilled, (state, action) => {
			state.featureList = action.payload;
		});
		builder.addCase(fetchRoleList.fulfilled, (state, action) => {
			state.roleList = action.payload;
		});
	},
});

export const {
	setSelectedFeature,
	setSelectedFeatureEnabled,
	setSelectedFeatureType,
	setRoleList,
	deleteFromRoleList,
	filterRoleList,
	setSelectedRole,
	setSelectedRoleEnabled,
	setSelectedRoleFeature,
	setSelectedRoleFeatureListId,
	setNewFeatureEnabled,
	setNewFeatureType,
} = appAdminSlice.actions;

export const selectAppAdmin = (state: RootState) => state.appAdmin;
export const selectRoleList = (state: RootState) => state.appAdmin.roleList;

export default appAdminSlice.reducer;
