import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../reducer/hooks";
import { alerting } from "../reducer/alerterSlice";
import { setToken } from "../reducer/profileSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { selectProfile } from "../reducer/profileSlice";

//purpose:
//1. save accessToken to redux
//2. redirect to admin / super admin page base on isLogged and isSuperAdmin
export default function Redirect() {
	let navigate = useNavigate();
	let [searchParams] = useSearchParams();
	const dispatch = useAppDispatch();
	const accessToken: string = searchParams.get("accessToken") || "";
	const { isLogged, isSuperAdmin } = useAppSelector(selectProfile);

	useEffect(() => {
		dispatch(setToken(accessToken)); //purpose 1.
	}, [accessToken]);

	//purpose 2.
	useEffect(() => {
		if (!isLogged) return;
		const toUrl = isSuperAdmin
			? "/super-admin/create-app"
			: "/app-admin/app-management";
		navigate(toUrl);
	}, [isLogged, isSuperAdmin]);
	if (accessToken.length === 0) {
		alerting("error", "invalid token");
		return <></>;
	}
	return <>redirecting</>;
}
