import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import style from "../account/Account.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/MenuBook";
import FunctionIcon from "@mui/icons-material/Build";
import { useState, useEffect } from "react";
import api from "../../api";
import { alerting } from "../../reducer/alerterSlice";
import { useAppDispatch, useAppSelector } from "../../reducer/hooks";
import {
	selectAppAdmin,
	setSelectedRoleEnabled,
	fetchFeatureList,
	fetchRoleDetail,
} from "../../reducer/appAdminSlice";
import Box from "@mui/material/Box";
import {
	filterDifferenceArray,
	generateNonce,
	getLeafNodes,
	numberToBol,
	removeNegative,
	flow,
	getAllParentIds,
} from "../../utils/helper";
import { getChildById } from "../../utils/treeview";
import StyledTreeItem from "../../components/StyledTreeItem";
import TreeView from "@mui/lab/TreeView";
import MinusSquare from "../../components/MinusSquare";
import PlusSquare from "../../components/PlusSquare";
import CloseSquare from "../../components/CloseSquare";
import Checkbox from "@mui/material/Checkbox";
import { IFeature, IParentFeatureList } from "../../interface/appAdminFace";
import { selectCurrentApp } from "../../reducer/appListSlice";
import FormControlLabel from "@mui/material/FormControlLabel";
const initUserInputs = {
	roleName: "",
	description: "",
};
export default function PreEditRole() {
	const dispatch = useAppDispatch();
	let navigate = useNavigate();
	const state = useAppSelector(selectAppAdmin);
	const { roleList } = state;
	const appId = useAppSelector(selectCurrentApp)?.appId as number;
	const { roleId } = useParams();
	const isLost = roleList.length === 0;
	const [fetchStatus, setFetchStatus] = useState("init");
	useEffect(() => {
		if (isLost) navigate("/app-admin/role-management");
	}, [isLost]);

	useEffect(() => {
		if (isLost) return;
		const isInvalid = !(roleId && appId);
		if (isInvalid) return;
		async function fetch() {
			try {
				await Promise.all([
					dispatch(fetchFeatureList(appId)).unwrap(),
					dispatch(fetchRoleDetail(Number(roleId))).unwrap(),
				]);
				setFetchStatus("fufill");
			} catch {
				setFetchStatus("fail");
			}
		}
		fetch();
	}, [appId, roleId, isLost]);
	if (isLost) return <></>; // web was refreshed, state is lost
	if (fetchStatus !== "fufill") return <></>; //api data is not ready
	return <EditRole />;
}
function EditRole() {
	const { t } = useTranslation();
	let navigate = useNavigate();
	const { roleId } = useParams();
	const dispatch = useAppDispatch();
	const state = useAppSelector(selectAppAdmin);
	const fullFeatureList = state.featureList;
	const dbSelectedIds = state.selectedRole.FeatureListId;
	const parentFeatureList: IParentFeatureList = {
		id: -1,
		name: "All Feature",
		children: fullFeatureList,
		featureType: 1,
	};
	const [selectedIds, rawSetSelectedIds] = useState<number[]>(dbSelectedIds);
	const setSelectedIds = (tickedIds: Array<number>) => {
		const wrapFn = flow(
			getAllParentIds,
			Object.keys,
			(ids) => ids.map((str) => Number(str)),
			rawSetSelectedIds
		);
		const initPath = { ["-1"]: true };
		const stringIds = tickedIds.join(",");
		wrapFn(parentFeatureList, stringIds, initPath);
	};
	const [userInputs, setUserInputs] = useState(initUserInputs);
	useEffect(() => {
		const theIds =
			dbSelectedIds.length > 0 ? [...dbSelectedIds, -1] : dbSelectedIds;
		setSelectedIds(theIds);
	}, [dbSelectedIds]);
	const popsError = (text: string) => dispatch(alerting("error", text));
	function handleSelectedStatus(event: any) {
		dispatch(setSelectedRoleEnabled(event.target.value));
	}
	const setInputData = (e: React.ChangeEvent<HTMLInputElement>) => {
		const key = e.target.name;
		const value = e.target.value;
		setUserInputs((data) => ({ ...data, [key]: value }));
	};
	const onRoleUpdateSave = async () => {
		const selectedLeafNodes = getLeafNodes([parentFeatureList]);
		const selectedFeatureList = removeNegative(
			filterDifferenceArray(selectedLeafNodes, selectedIds)
		);
		// if (JSON.stringify(selectedFeatureList) === JSON.stringify(dbSelectedIds)) return popsError("No Feature Change has been made");
		if (selectedFeatureList.length === 0)
			return popsError("Feature Cannot be Empty");
		const nonce = generateNonce();
		const data = {
			nonce,
			roleName:
				userInputs.roleName.length > 0
					? userInputs.roleName
					: state.selectedRole.roleName,
			description:
				userInputs.description.length > 0
					? userInputs.description
					: state.selectedRole.description,
			featureIdList: selectedFeatureList,
			isEnabled: numberToBol(state.selectedRole.status),
			updateField: 253,
		};
		let result: any = null;
		result = await api.admin.updateRole(Number(roleId), data).catch(Error);
		if (result && result.returnCode < 0) return popsError(result.message);
		navigate(-1);
		dispatch(alerting("success", "Role Save Success"));
	};

	function getOnChange(checked: boolean, nodes: IFeature | IParentFeatureList) {
		const allNode: number[] = getChildById(parentFeatureList, nodes.id);

		let array = checked
			? [...selectedIds, ...allNode]
			: selectedIds.filter((value) => !allNode.includes(value));
		array = array.filter((v, i) => array.indexOf(v) === i);
		setSelectedIds(array);
	}

	function renderTree(nodes: IFeature | IParentFeatureList) {
		const theForm = (
			<FormControlLabel
				control={
					<Checkbox
						checked={selectedIds.some((item) => item === nodes.id)}
						onChange={(event) =>
							getOnChange(event.currentTarget.checked, nodes)
						}
						onClick={(e) => e.stopPropagation()}
					/>
				}
				key={nodes.id}
				label={
					<>
						{nodes?.featureType === 1 ? <MenuIcon /> : <FunctionIcon />}
						{nodes.name}
					</>
				}
			/>
		);
		return (
			<StyledTreeItem
				key={nodes.id}
				nodeId={nodes.id.toString()}
				label={theForm}
			>
				{Array.isArray(nodes.children)
					? nodes.children.map((node: any) => renderTree(node))
					: null}
			</StyledTreeItem>
		);
	}
	return (
		<div className={style["content-wrap"]}>
			<div className={style["title"]}>{t("appAdmin.editRole")}</div>
			<div
				className={`${style["li-container"]} ${style["li-container-edit-user"]}`}
			>
				<div className={`${style["li-label"]} ${style["li-label-long"]}`}>
					{t("appAdmin.roleName")}
				</div>
				<div className={style["li-value"]}>
					<TextField
						key={`key-${state.selectedRole.roleName}`}
						name="roleName"
						defaultValue={state.selectedRole.roleName}
						onChange={setInputData}
						variant="outlined"
						inputProps={{
							style: {
								padding: 5,
							},
						}}
					/>
				</div>
			</div>
			<div
				className={`${style["li-container"]} ${style["li-container-edit-user"]}`}
			>
				<div className={`${style["li-label"]} ${style["li-label-long"]}`}>
					{t("appAdmin.description")}
				</div>
				<div className={style["li-value"]}>
					<TextField
						key={`key-${state.selectedRole.description}`}
						name="description"
						defaultValue={state.selectedRole.description}
						onChange={setInputData}
						variant="outlined"
						inputProps={{
							style: {
								padding: 5,
							},
						}}
					/>
				</div>
			</div>
			<div
				className={`${style["li-container"]} ${style["li-container-edit-user"]}`}
			>
				<div className={`${style["li-label"]} ${style["li-label-long"]}`}>
					{t("appAdmin.status")}
				</div>
				<div className={style["li-value"]}>
					<Select
						onChange={handleSelectedStatus}
						key={`isEnabled-${state.selectedRole.roleName}`}
						defaultValue={state.selectedRole.status}
						value={state.selectedRole.status}
					>
						<MenuItem value={1}>{t("account.enabled")}</MenuItem>
						<MenuItem value={-1}>{t("account.disabled")}</MenuItem>
					</Select>
				</div>
			</div>
			<div
				className={`${style["li-container"]} ${style["li-container-edit-user"]}`}
			>
				<div className={`${style["li-label"]} ${style["li-label-long"]}`}>
					{t("appAdmin.assignedRoles")}
				</div>
			</div>
			{fullFeatureList.length > 0 && (
				<Box sx={{ display: "flex", mx: 5 }}>
					<TreeView
						aria-label="customized"
						defaultExpanded={["-1"]}
						defaultCollapseIcon={<MinusSquare />}
						defaultExpandIcon={<PlusSquare />}
						defaultEndIcon={<CloseSquare />}
						sx={{ height: 264, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
					>
						{renderTree(parentFeatureList)}
					</TreeView>
				</Box>
			)}
			<div className={`${style["li-button"]} ${style["li-button-long"]}`}>
				<Button color="primary" variant="contained" onClick={onRoleUpdateSave}>
					{t("account.submit")}
				</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={() => navigate("/app-admin/role-management")}
				>
					{t("account.back")}
				</Button>
			</div>
		</div>
	);
}
