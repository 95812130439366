import { useEffect, useState } from "react";
import style from "../account/Account.module.scss";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StyledTableCell from "../../components/StyledTableCell";
import StyledTableRow from "../../components/StyledTableRow";
import { useAppDispatch, useAppSelector } from "../../reducer/hooks";
import {
	selectAppUsers,
	fetchAppUsers,
	deleteAppUser,
} from "../../reducer/appUserSlice";
import { alerting } from "../../reducer/alerterSlice";
import { selectAppList } from "../../reducer/appListSlice";
import { openConfirmDialog } from "../../reducer/confirmDialogSlice";
import ConfirmDialog from "../../components/ConfirmDialog";

const initUserInput = {
	addField: "",
	searchField: "",
};
const initTargetUser = {
	userId: 0,
	name: "",
};
export default function UserManagement() {
	const { t } = useTranslation();
	let navigate = useNavigate();
	const dispatch = useAppDispatch();
	const appId = useAppSelector(selectAppList).selected;
	const [userInput, setUserInput] = useState(initUserInput);
	const [targetUser, setTargetUser] = useState(initTargetUser);
	const rawUserList = useAppSelector(selectAppUsers).list;
	const userList = getUserList();
	function getUserList() {
		const keyword = userInput.searchField;
		const allUserList = rawUserList.map((u) => {
			const len = u.roleList.length;
			const displayRole = len <= 1 ? u.roleList[0]?.roleName : `${len} Roles`;
			return { ...u, displayRole };
		});
		if (keyword.length === 0) return allUserList;
		return allUserList.filter((u) => {
			const content = [
				u.alias,
				u.email,
				...u.roleList.map((r) => r.roleName),
			].join(",,,");
			return content.includes(keyword);
		});
	}
	useEffect(() => {
		if (appId === -1) return;
		dispatch(fetchAppUsers(appId)); //thunk
	}, [dispatch, appId]);
	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const key = e.target.name;
		const value = e.target.value;
		setUserInput((s) => ({ ...s, [key]: value }));
	};
	const onAddUser = () => {
		navigate("/app-admin/user-management/add", {
			state: { type: "add" },
		});
	};
	const onEditUser = (userInfo: any) => {
		navigate("/app-admin/user-management/edit", {
			state: { type: "edit", userInfo },
		});
	};
	const onDeleteUser = async (data: any) => {
		const { name, userId } = data;
		try {
			const result = await dispatch(
				deleteAppUser({ userId, appId, name })
			).unwrap();
			dispatch(alerting("success", `deleted user ${result.name}`));
		} catch {}
	};
	if (appId === -1) return <></>;
	return (
		<div className={style["content-wrap"]}>
			<div className={style["title"]}>{t("appAdmin.userList")}</div>
			<div className={style["li-containers-row"]}>
				<div className={style["li-container"]}>
					<div className={`${style["li-button"]} ${style["side-button"]}`}>
						<Button variant="contained" onClick={onAddUser}>
							{t("appAdmin.add")} {t("appAdmin.user")}
						</Button>
					</div>
				</div>
				<div className={style["li-container"]}>
					<div className={style["li-value"]}>
						<TextField
							name="searchField"
							placeholder="search"
							value={userInput.searchField}
							onChange={onInputChange}
							variant="outlined"
							inputProps={{
								style: {
									padding: 5,
								},
							}}
						/>
					</div>
				</div>
			</div>
			<TableContainer component={Paper}>
				<Table sx={{ maxWidth: 1000 }} aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell>{t("appAdmin.alias")}</StyledTableCell>
							<StyledTableCell>{t("appAdmin.email")}</StyledTableCell>
							<StyledTableCell align="center">
								{t("appAdmin.role")}
							</StyledTableCell>
							<StyledTableCell align="center">
								{t("appAdmin.status")}
							</StyledTableCell>
							<StyledTableCell align="center">
								{t("appAdmin.setting")}
							</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{userList.map((user) => (
							<StyledTableRow key={user.id}>
								<StyledTableCell component="th" scope="row">
									{user.alias}
								</StyledTableCell>
								<StyledTableCell component="th" scope="row">
									{user.email}
								</StyledTableCell>
								<StyledTableCell align="right">
									{user.displayRole}
								</StyledTableCell>
								<StyledTableCell align="right">
									{user.status === 1 ? "Enable" : "Disable"}
								</StyledTableCell>
								<StyledTableCell align="right">
									<Button
										variant="contained"
										sx={{ backgroundColor: "var(--main)" }}
										onClick={() => onEditUser(user)}
									>
										{t("superAdmin.edit")}
									</Button>
									<Button
										variant="contained"
										sx={{ backgroundColor: "var(--black)" }}
										onClick={() => {
											dispatch(openConfirmDialog({ id: 0, name: "" }));
											setTargetUser({ userId: user.id, name: user.alias });
										}}
									>
										{t("superAdmin.delete")}
									</Button>
								</StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<ConfirmDialog
				title={`Confirm to delete User ${targetUser.name}?`}
				onConfirm={() => onDeleteUser(targetUser)}
			/>
		</div>
	);
}
