import style from "./AppManagement.module.scss";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "../../reducer/hooks";
import { selectAppList } from "../../reducer/appListSlice";
import { alerting } from "../../reducer/alerterSlice";
import api from "../../api";
import { hasPath } from "../../utils/helper";
import LiItem from "../../components/LiItem";

const initUserInput = {
	appName: "",
	isEnableTwoFA: false,
	iconUrl: "",
	loginTokenDuration: 0,
	secret: "",
	loginCallbackUrl: "",
	appId: -1,
	appUuid: "",
};
export default function AppManagement() {
	const { t } = useTranslation();
	const [isInEdit, setIsInEdit] = React.useState(false);
	const [userInput, setUserInput] = React.useState(initUserInput);
	const dispatch = useAppDispatch();
	const appId = useAppSelector(selectAppList).selected;
	useEffect(() => {
		async function fetchAppConfig() {
			if (appId <= 0) return;
			const result: any = await api.admin.getAppConfig(appId);
			if (!result) return;
			const data = result.data;
			const isMissing = !hasPath(data, [
				"appName",
				"isEnableTwoFA",
				"loginCallbackUrl",
				"loginTokenDuration",
			]);
			if (isMissing) return dispatch(alerting("error", "data is missing"));
			const loginTokenDuration = Math.floor(data.loginTokenDuration / 60);
			setUserInput({ ...data, loginTokenDuration });
		}
		fetchAppConfig();
	}, [dispatch, appId]);
	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const key = e.target.name;
		function getValue() {
			const value = e.target.value;
			return key === "loginTokenDuration" ? Number(value) : value;
		}
		const value = getValue();
		setUserInput((s) => ({ ...s, [key]: value }));
	};
	const onSelectChange = (e: SelectChangeEvent<string>) => {
		const isEnableTwoFA = e.target.value === "true";
		setUserInput((input) => ({ ...input, isEnableTwoFA }));
	};
	const submit = async () => {
		const params = {
			updateField: 47, // all field but secret
			...userInput,
			loginTokenDuration: userInput.loginTokenDuration * 60,
		};
		const result = await api.admin.updateAppConfig(params);
		if (!result) return;
		dispatch(alerting("success", "Saved Config"));
		setIsInEdit(false);
	};
	if (appId <= 0) return <></>;

	return (
		<div className={style["content-wrap"]}>
			<div className={style["title"]}>{t("appAdmin.appManagement")}</div>
			<LiItem title="App ID">
				<TextField
					disabled={true}
					variant="outlined"
					name="appId"
					onChange={onInputChange}
					value={userInput.appId}
				/>
			</LiItem>
			<LiItem title="App UUID">
				<TextField
					disabled={true}
					variant="outlined"
					name="appUUID"
					onChange={onInputChange}
					value={userInput.appUuid}
				/>
			</LiItem>
			<LiItem title="App Name">
				<TextField
					disabled={!isInEdit}
					variant="outlined"
					name="appName"
					onChange={onInputChange}
					value={userInput.appName}
				/>
			</LiItem>
			<LiItem title={t("appAdmin.googleAuthentication")}>
				<Select
					disabled={!isInEdit}
					name="isEnableTwoFA"
					value={userInput.isEnableTwoFA ? "true" : "false"}
					onChange={onSelectChange}
					displayEmpty
					sx={{
						color: "var(--black)",
						bgcolor: "var(--white)",
					}}
				>
					<MenuItem value={"true"}>Enable</MenuItem>
					<MenuItem value={"false"}>Disable</MenuItem>
				</Select>
			</LiItem>
			<LiItem title="Icon URL">
				<TextField
					disabled={!isInEdit}
					variant="outlined"
					name="iconUrl"
					onChange={onInputChange}
					value={userInput.iconUrl}
				/>
			</LiItem>
			<LiItem title="Login URL">
				<TextField
					disabled={!isInEdit}
					variant="outlined"
					name="loginCallbackUrl"
					onChange={onInputChange}
					value={userInput.loginCallbackUrl}
				/>
			</LiItem>
			<LiItem title="Token Duration (minute)">
				<TextField
					disabled={!isInEdit}
					variant="outlined"
					name="loginTokenDuration"
					onChange={onInputChange}
					type="number"
					value={userInput.loginTokenDuration}
				/>
			</LiItem>
			<LiItem title="Secret">
				<TextField
					variant="outlined"
					name="secret"
					onChange={onInputChange}
					value={userInput.secret}
					disabled
				/>
			</LiItem>
			{!isInEdit && (
				<div className={style["li-button"]}>
					<Button
						color="primary"
						variant="contained"
						onClick={() => setIsInEdit(true)}
					>
						Edit
					</Button>
				</div>
			)}
			{isInEdit && (
				<div className={style.btnGroups}>
					<Button color="primary" variant="contained" onClick={submit}>
						Save
					</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={() => setIsInEdit(false)}
					>
						Cancel
					</Button>
				</div>
			)}
		</div>
	);
}
