import getAxiosInstance from "./axios";
const host = process.env.REACT_APP_ADMIN_ENDPOINT as string;
if (!host) throw Error("no host");
const api = getAxiosInstance(host, "admin");

interface createFeatureFace {
	appId: number;
	name: string;
	code: string;
	featureType: number;
	parentFeatureId: number;
	isEnabled: boolean;
	tag: string;
}

interface updateFeatureFace {
	appId: number;
	name: string;
	code: string;
	featureType: number;
	parentFeatureId: number;
	isEnabled: number;
	tag: string;
	updateField: number;
}

interface createRoleFace {
	nonce: string;
	appId: number;
	roleName: string;
	description: string;
	featureList: number | number[] | undefined;
}

interface updateRoleFace {
	nonce: string;
	roleName: string;
	description: string;
	updateField: number;
	isEnabled: boolean;
	featureIdList: Array<number>;
}
interface userListFace {
	appId: number;
	nonce: string;
	email?: string;
	state?: number;
	roleName?: string;
}
interface updateAppFace {
	appId: number;
	updateField: number;
	nonce?: string;
	isEnableTwoFA?: boolean;
	appName?: string;
	loginCallbackUrl?: string;
	iconUrl?: string;
	loginTokenDuration?: number;
	oneOffTokenDuration?: number;
}
interface getAppUsersFace {
	appId: number;
	email?: string;
	state?: number;
	roleName?: string;
	nonce?: string;
}
export interface updateAppUserFace {
	appId: number;
	userId: number;
	updateField: number;
	email?: string;
	alias?: string;
	roleIdList: number[];
	status?: number;
	nonce?: string;
}
export interface addAppUserFace {
	appId: number;
	email: string;
	alias?: string;
	roleIdList: number[];
	nonce?: string;
}
export interface deleteAppUserFace {
	appId: number;
	userId: number;
	name?: string;
}

const apiList = {
	appIdList: () => api.get("appIdList"),
	featureList: (appId: number) => api.get(`feature/list?appId=${appId}`),
	createFeature: (data: createFeatureFace) => api.post("feature/create", data),
	updateFeature: (featureId: number, data: updateFeatureFace) =>
		api.post(`feature/update/${featureId}`, data),
	deleteFeature: (featureId: number) =>
		api.delete(`feature/delete/${featureId}`),
	roleList: (appId?: number) => api.get(`role/list?appId=${appId}`),
	roleDetail: (roleId?: number) => api.get(`role/${roleId}`),
	createRole: (data: createRoleFace) => api.post("role/create", data),
	deleteRole: (featureId: number) => api.delete(`role/delete/${featureId}`),
	updateRole: (featureId: number, data: updateRoleFace) =>
		api.post(`role/update/${featureId}`, data),
	userList: (params: userListFace) => api.get("user/list", { params }),
	getAppConfig: (appId: number) => api.get(`app/${appId}`),
	updateAppConfig: (data: updateAppFace) => {
		const { appId, ...rest } = data;
		return api.post(`app/update/${appId}`, rest);
	},
	getAppUsers: (params: getAppUsersFace) => api.get("user/list", { params }),
	addAppUser: (data: addAppUserFace) => api.post("user/add", data),
	updateAppUser: (data: updateAppUserFace) => {
		const { appId, userId, ...rest } = data;
		return api.post(`user/update/${appId}/${userId}`, rest);
	},
	deleteAppUser: (data: deleteAppUserFace) =>
		api.delete(`user/delete/${data.appId}/${data.userId}`),
};

export default apiList;
