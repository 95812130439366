const dictionary = {
	enable: "enable",
	disable: "disable",
	currency: "貨幣",
	login: {
		password: "密碼",
	},
	register: "注冊",
	visitor: {
		ga: "ga",
		verify: "Verify",
		enterVerifyCode: "Enter Verify Code",
		sendAgain: "Send Again",
		submit: "Submit",
		accountLogin: "Account Login",
		login: "login",
		email: "email",
		password: "password",
		rememberMeNextTime: "remember me next time",
		confirm: "Confirm",
		notRegistered: "Not Registered",
		forgotPassword: "Forgot Password",
	},
	account: {
		userAuth: "User Auth",
		accountInfo: "Account Info",
		googleAuthentication: "Google Authentication",
		loginName: "Login Name",
		password: "Password",
		email: "Email",
		changePassword: "Change Password",
		updateEmail: "Update Email",
		submit: "Submit",
		back: "back",
		emailVerifyCode: "Email Verify Code",
		sendVerifyCode: "Send Verify Code",
		status: "Status",
		request: "Request",
		forgetPassword: "Forget Password",
		oldPassword: "Old Password",
		newPassword: "New Password",
		confirmNewPassword: "Confirm New Password",
		enterAccountPassword: "Enter Account Password",
		gaQRCodeToEmail: "Google Authentication QR code to registration email",
		send: "Send",
		enterYourGa: "Enter your Google Authentication code",
		logout: "Logout",
		disabled: "Disabled",
		enabled: "Enabled",
	},
	superAdmin: {
		createApp: "Create App",
		adminManagement: "Admin Management",
		appName: "App Name",
		description: "Description",
		addAdmin: "Add Admin",
		add: "Add",
		create: "Create",
		userName: "User Name",
		role: "Role",
		status: "Status",
		setting: "Setting",
		edit: "Edit",
		delete: "Delete",
	},
	appAdmin: {
		appManagement: "App Management",
		userManagement: "User Management",
		user: "User",
		userList: "User List",
		roleManagement: "Role Management",
		featureManagement: "Feature Management",
		googleAuthentication: "Google Authentication",
		submit: "Submit",
		back: "Back",
		enable: "Enable",
		disable: "Disable",
		add: "Add",
		search: "Search",
		createRole: "Create Role",
		addUser: "Add User",
		description: "Description",
		userName: "User Name",
		email: "Email",
		assign: "Assign",
		role: "Role",
		roleName: "Role Name",
		status: "Status",
		setting: "Setting",
		edit: "Edit",
		delete: "Delete",
		editRole: "Edit Role",
		alias: "Alias",
		assignRole: "Feature",
		assignedRoles: "Feature",
		featuresTree: "Features Tree",
		code: "Code",
		name: "Name",
		type: "Type",
		featureType: "Type",
		permissions: "Permissions",
		url: "URL",
		save: "Save",
		menu: "Menu",
		function: "Function",
		disabled: "Disabled",
		enabled: "Enabled",
		tag: "Tag",
		noRecord: "No Record",
	},
};
export default dictionary;
